<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div style="display: flex; margin-bottom: 5px">
        <el-form :model="SerachInput" label-width="90px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="发送时间" class="timeRight">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="通知方式" class="timeRight">
              <el-select
                v-model="SerachInput.noticeType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opStatus"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通知状态">
              <el-select
                v-model="SerachInput.noticeState"
                placeholder="请选择"
                clearable
              >
                <el-option key="" label="全部" value="" />
                <el-option
                  v-for="item in opProduct"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="单据编号">
              <el-input
                v-model="SerachInput.billNo"
                placeholder="请输入单据编号"
                style="width: 240px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="通知号码" class="timeRightOne">
              <el-input
                v-model="SerachInput.userPhone"
                placeholder="请输入通知号码"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="设备编号" class="timeRightOne1">
              <el-input
                v-model="SerachInput.imei"
                placeholder="请输入设备编号"
                style="width: 180px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="企业编号">
              <el-input
                v-model="SerachInput.companyNumber"
                placeholder="请输入企业编号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <img
              src="../../assets/images/query.png"
              alt=""
              @click="imaClick"
              style="
                width: 49px;
                height: 29px;
                margin: 6px;
                margin-top: 5px;
                margin-left: 10px;
              "
            />
          </div>
        </el-form>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="billNo"
            label="编号"
            width="240px"
          ></el-table-column>
          <el-table-column
            prop="companyNumber"
            label="企业编号"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="imei"
            label="报警设备"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="通知人员"
            width="110px"
          ></el-table-column>
          <el-table-column
            prop="userPhone"
            label="通知号码"
            width="140px"
          ></el-table-column>
          <el-table-column
            prop="noticeTypeCn"
            label="方式"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="noticeTime"
            label="发送时间"
            width="180px"
          ></el-table-column>
          <el-table-column
            prop="noticeStateCn"
            label="通知状态"
            width="110px"
          ></el-table-column>
          <el-table-column prop="deductionAmount" label="金额" width="120px">
            <template slot-scope="scope">
              <span
                ><span class="qian">¥</span
                >{{ scope.row.deductionAmount }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            查询订单总数:{{ totalCount }}
            <span class="amount">查询订单金额:¥{{ totalAmount }}</span>
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
          <div class="buttons">
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="账单详情"
      :visible.sync="dialogVisible"
      width="52%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-row>
          <el-col :span="18">
            <!-- <el-form-item label="点位信息" class="senOne"> -->
            <div class="inforOne">
              <span class="inforDian">点位信息</span>
              <el-input
                v-model="form.pointInformation"
                placeholder="请输入点位信息"
                :disabled="true"
                @input="$forceUpdate()"
                class="inforInput"
              ></el-input>
            </div>

            <!-- </el-form-item> -->
          </el-col>
        </el-row>
        <div class="bang">
          <span>报警信息</span>
        </div>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="报警设备" class="senOne">
              <el-input
                v-model="form.productName"
                placeholder="请输入报警设备"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备编号">
              <el-input
                v-model="form.imei"
                placeholder="请输入设备编号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="报警类型" class="senOne">
              <el-input
                v-model="form.situationCn"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报警时间">
              <el-input
                v-model="form.noticeTime"
                placeholder="请输入报警时间"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="bang">
          <span>通知信息</span>
        </div>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="通知人员" class="senOne">
              <el-input
                v-model="form.userName"
                placeholder="请输入通知人员"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="通知号码">
              <el-input
                v-model="form.userPhone"
                placeholder="请输入通知号码"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="通知方式" class="senOne">
              <el-input
                v-model="form.noticeTypeCn"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="通知状态">
              <el-input
                v-model="form.noticeStateCn"
                placeholder="请输入通知状态"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="bang">
          <span>费用信息</span>
        </div>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="消费金额" class="senOne">
              <el-input
                v-model="form.deductionAmount"
                placeholder="请输入消费金额"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业编号">
              <el-input
                v-model="form.companyNumberOne"
                placeholder="请输入企业编号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearUserForm">取 消</el-button>
        <el-button type="primary" @click="unSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        pointInformation: "北京市",
        userName: "",
        userPhone: "",
        noticeTypeCn: "",
        noticeStateCn: "",
        deductionAmount: "",
        imei: "",
        productName: "",
        situationCn: "",
        noticeTime: "",
        companyNumberOne: "",
      },
      tableData: [],
      rules: {},
      opSituation: [],
      SerachInput: {},
      overallForm: {
        time: [],
      },
      opProduct: [],
      opStatus: [
        {
          code: "",
          name: "全部",
        },
        {
          code: "1",
          name: "电话语音",
        },
        {
          code: "2",
          name: "短信通知",
        },
      ],
      clientHeight: document.body.clientHeight,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      totalAmount: "",
    };
  },
  created() {
    this.getList();
    this.sta();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleDelete(index, row) {
      // console.log("详情：", index, row);
      this.dialogVisible = true;
      apiShout.billDetail({ billNo: row.billNo }).then((res) => {
        // console.log("查看：", res.data);
        if (res.data.code == 200) {
          this.form = res.data.result;
          this.form.pointInformation =
            res.data.result.pointRegion +
            "-" +
            res.data.result.pointSubarea +
            "-" +
            res.data.result.pointName +
            row.userName;
          this.form.userName = row.userName;
          this.form.userPhone = row.userPhone;
          this.form.companyNumberOne = res.data.result.companyNumber;
          this.form.noticeTime = row.noticeTime;
          this.form.deductionAmount = "¥ " + res.data.result.deductionAmount;
        }
      });
    },
    unSubmit() {
      this.dialogVisible = false;
    },
    clearUserForm() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    imaClick() {
      // this.getList();
      apiShout
        .billList({
          pageSize: this.pageSize,
          pageCount: 1,
          noticeStart:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          noticeEnd:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          noticeType: this.SerachInput.noticeType,
          noticeState: this.SerachInput.noticeState,
          billNo: this.SerachInput.billNo,
          userPhone: this.SerachInput.userPhone,
          imei: this.SerachInput.imei,
          companyNumber: this.SerachInput.companyNumber,
        })
        .then((res) => {
          // console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.totalAmount = res.data.result.totalAmount;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    sta() {
      api.getSelectProduct({ dictCode: "alarmnoticestate" }).then((res) => {
        // console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.opProduct = res.data.result;
        }
      });
    },
    getList() {
      apiShout
        .billList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          noticeStart:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          noticeEnd:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          noticeType: this.SerachInput.noticeType,
          noticeState: this.SerachInput.noticeState,
          billNo: this.SerachInput.billNo,
          userPhone: this.SerachInput.userPhone,
          imei: this.SerachInput.imei,
          companyNumber: this.SerachInput.companyNumber,
        })
        .then((res) => {
          // console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.totalAmount = res.data.result.totalAmount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/crdbill/exportList",
          params: {
            noticeStart:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            noticeEnd:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            noticeType: this.SerachInput.noticeType,
            noticeState: this.SerachInput.noticeState,
            billNo: this.SerachInput.billNo,
            userPhone: this.SerachInput.userPhone,
            imei: this.SerachInput.imei,
            companyNumber: this.SerachInput.companyNumber,
          },
          method: "post",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "消费查询" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
  },
};
</script>

<style lang='less' scoped>
.bang {
  font-size: 16px;
  // font-weight: 700;
  margin-bottom: 20px;
}
.inforOne {
  display: flex;
  margin-bottom: 20px;
}
.inforInput {
  width: 582px;
}
.inforDian {
  font-size: 16px;
  // font-weight: 700;
  width: 90px;
  margin-top: 3px;
}
.police {
  margin-left: 10px;
}
.senOne {
  margin-right: 25px;
}
.xian1 {
  width: 103%;
  height: 2px;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  margin-bottom: 20px;
}
.baocun {
  background: hsl(3deg 31% 57%);
  border: none;
  padding: 8px 25px;
  font-size: 21px;
  color: #fff;
  border-radius: 30px;
}
/deep/ .el-dialog__body {
  padding: 0;
  margin-left: 37px;
}
.amount {
  margin-left: 20px;
}
.timeRight {
  margin-right: 15px;
}
.timeRightOne {
  margin-right: 23px;
}
.timeRightOne1 {
  margin-right: 62px;
}
// /deep/ .el-input.is-disabled .el-input__inner {
//   font-size: 16px;
// }
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-input__icon {
  height: auto;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 10px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
</style>